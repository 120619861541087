.weapons-table {
  width: 90%;
  text-align: center;
  vertical-align: center;
}

.name-column {
  width: 10%;
}

.weapon-level-column,
.uses-column,
.weight-column,
.might-column,
.hit-column,
.critical-column,
.range-column,
.cost-column {
  width: 5%;
}

.favorite-tea-column {
  width: 15%;
}
